<script>
    import editgongsi from "./editgongsi/index";

    export default {
        components: {
            editgongsi
        },
        name: "gongsi",
        data() {
            return {
                edit_info: false,
                edit_time: 0,

                edit_info1: false,
                edit_time1: 0,

                formInline: {
                    name: '',
                },

                gongshi_id: 0,
                drawer_visible: false,
                drawer_visible1: false,
                // 分页
                page_show: false,
                page: 1,
                count: 0,
                pagesize: 0,
                mechanicallydata: [],
                all_check: false,
            };
        },
        watch: {
            formInline: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },
            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },

            itemActiveChange() {
                let do_ = true
                this.mechanicallydata.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.mechanicallydata.map((item, key) => {
                    this.$set(this.mechanicallydata[key], 'check', this.all_check)
                })
            },
        },
        mounted() {
            this.mountedDo()
        },
        methods: {
            clickrow(key) {
                this.$set(this.mechanicallydata[key], 'check', !this.mechanicallydata[key].check)
                this.itemActiveChange()
            },
            itemActiveChange() {
                let do_ = true
                this.mechanicallydata.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.mechanicallydata.map((item, key) => {
                    this.$set(this.mechanicallydata[key], 'check', this.all_check)
                })
            },
// 导出
            mechanically_export() {
                console.log(JSON.stringify({
                    name: this.formInline.name,
                }))
                this.$sa0.down({
                    url: this.$api('Mechanically_export'),
                    file_name: 'down1.xlsx',
                    data: {
                        name: this.formInline.name,
                    },
                },)
            },
            mountedDo() {
                this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                this.formInline.name = this.$route.query.name || '';
                this.Get_mechanically();
                setTimeout(() => {
                    this.page_show = true
                }, 1)
            },
            // 公式列表
            Get_mechanically() {
                this.$sa0.post({
                    url: this.$api('Get_mechanically'),
                    data: {
                        name: this.formInline.name,
                        page: this.page,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.mechanicallydata = response.data.return.list
                            this.count = Number(response.data.return.count);//分页
                            this.pagesize = Number(response.data.return.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            //删除化学式
            delClick() {
                let id = 0
                let count = 0
                this.mechanicallydata.map((item, key) => {
                    if (item.check) {
                        id = item.id
                        count++
                    }
                })
                if (!id) return layer.msg('请选择公式')
                if (count !== 1) return layer.msg('只能选择一个公式')
                console.log(987, this.edit_info1)
                this.$sa0.post({
                    url: this.$api('Del_mechanicall'),
                    data: {
                        id: id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_mechanically()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Clear() {
                this.formInline.name = '';
                this.page = 1;
            },
            // 搜索
            searchClick() {
                this.page = 1;
                this.$router.push({
                    query: {
                        name: this.formInline.name,
                    }
                })
            },
            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                        name: this.formInline.name,
                    }
                })
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
                this.Get_mechanically();
            },
            // 定义 打开抽屉时的 函数
            showDrawer(item) {
                this.edit_info = item;
                this.edit_time = new Date() / 1;
                this.drawer_visible = true;
            },


            // 定义 关闭抽屉时的 函数
            onCloseDrawer1(item) {
                this.drawer_visible1 = false;
                this.Get_mechanically();
            },
            // 定义 打开抽屉时的 函数
            editshowDrawer() {
                let id = 0
                let count = 0
                this.mechanicallydata.map((item, key) => {
                    if (item.check) {
                        this.edit_info1 = item;
                        id = item.id
                        count++
                    }
                })
                if (!id) return layer.msg('请选择公式')
                if (count !== 1) return layer.msg('只能选择一个公式')
                console.log(987, this.edit_info1)
                setTimeout(() => {
                    this.edit_time1 = new Date() / 1;
                    this.drawer_visible1 = true;
                })
            },
            closegongshi() {
                this.edit_info = false
                this.gongshi_id = 0
                this.mountedDo()
            },
            editgongshi(type) {
                this.edit_info = false;
                if (type === 0) {
                    this.gongshi_id = 0
                    this.edit_info = true
                } else {
                    let id = 0
                    let count = 0
                    this.mechanicallydata.map((item, key) => {
                        if (item.check) {
                            id = item.id
                            count++
                        }
                    })
                    if (!id) return layer.msg('请选择公式')
                    if (count !== 1) return layer.msg('只能选择一个公式')
                    this.gongshi_id = id
                    setTimeout(() => {
                        this.edit_info = true
                    })
                }
            },

        }
    }
</script>
<template>
    <div>
        <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
            <a-form-model-item label="公式名称">
                <a-input v-model="formInline.name" class="w200" placeholder="请输入公式名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="">
                <a-button type="danger" @click="Clear()">
                    清空搜索
                </a-button>
            </a-form-model-item>
        </a-form-model>

        <a-space style="margin-top: 10px;" class="btner">
            <div class="btnerlist" @click="editgongshi(0)">
                <i class="iconfont icon-jia" style="font-size: 16px"></i> 新建
            </div>

            <div class="btnerlist" @click="editgongshi(1)">
                <i class="iconfont icon-bianjimian" style="font-size: 16px"></i> 编辑
            </div>

            <div class="btnerlist">
                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                    <template slot="title">确认删除该项？</template>
                    <i class="iconfont icon-yixiaohui" style="font-size: 16px"></i> 删除
                </a-popconfirm>
            </div>
        </a-space>
        <table class="layui-table" style="margin-top: -9px" lay-size="sm">
            <thead>
            <tr>
                <th>
                    <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
                </th>
                <th>公式名称</th>
                <th>公式内容</th>
                <th>状态</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="( item,key) in mechanicallydata" :key="key" :class="[item.check ? 'active':'']" @click="clickrow(key)">
                <td>
                    <a-checkbox v-model='item.check' @change="itemActiveChange"></a-checkbox>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.formula }}</td>
                <td>{{ item.status_trun }}</td>
            </tr>
            </tbody>
        </table>
        <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
        <div v-if="edit_info">
            <editgongsi :gongshi_id="gongshi_id" :closegongshi="closegongshi" :edit_time1="edit_time1"></editgongsi>
        </div>
    </div>
</template>
<style scoped>
    .w200{
        width: 200px
        }

    table tr{
        cursor: pointer
        }


    .btner{
        width: 100%;
        background: #f9f9f9;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        margin-bottom: 10px;
        }

    .active{
        background: #eff8ff;
        }

    .btnerlist{
        cursor: pointer;
        border-right: 1px solid #eeeeee;
        height: 26px;
        line-height: 26px;
        padding: 0 10px
        }

    .btnerlist :last-child(1){
        border: none
        }

    .fcadd{
        color: #66c14c !important;
        font-size: 18px !important;
        }

    .btnerlist i{
        color: #999999;
        font-size: 14px;
        vertical-align: bottom;
        }

    .btnerlist:hover{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;

        }

    .btnerlist:hover i, .btnerlist:hover.fcadd{
        color: #ffffff !important
        }

    .isthis{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
        }

    .isthis i{
        color: #ffffff
        }


</style>
