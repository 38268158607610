<script>
    /**
     * name：
     * user：sa0ChunLuyu
     * date：2022-04-13 14:53:02
     */
    export default {
        props: ['gongshi_id', 'closegongshi'],
        data() {
            return {
                id: 0,
                name: '',
                input: "",
                status: 1,
                yinzi: [],
                yinzi_input: '',
                image: '',
                mechanicallyData: [],
                creatform: {
                    yinzi_name: ''
                }
            }
        },

        mounted() {
            this.onMounted()
            this.Get_mechanically()
        },
        methods: {
            // 化学式详情
            Get_mechanically() {
                if (this.id != 0) {
                    this.$sa0.post({
                        url: this.$api('Mechanically_detail'),
                        data: {
                            id: this.id
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.mechanicallyData = response.data.mechanically.yinzi.map((item) => {
                                    item.is_result = Number(item.is_result) === 1
                                    return item
                                })
                                // this.mechanicallyData = response.data.mechanically.yinzi
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })

                }

            },
            // 编辑因子
            Edityinzi(item) {
                this.$sa0.post({
                    url: this.$api('Save_mechanicallyinzi'),
                    data: {
                        mechanically_id: this.id,
                        yinzi_id: item.id,
                        yinzi_name: item.yinzi,
                        is_result: item.is_result ? '1' : '2',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功')
                            this.Get_mechanically()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 添加因子
            Addyinzi() {
                this.$sa0.post({
                    url: this.$api('Save_mechanicallyinzi'),
                    data: {
                        mechanically_id: this.id,
                        yinzi_id: 0,
                        yinzi_name: this.creatform.yinzi_name,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_mechanically()
                            this.creatform = {
                                yinzi_name: ''
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 删除因子
            Delyinzi(item) {
                this.$sa0.post({
                    url: this.$api('Del_mechanicallyinzi'),
                    data: {
                        yinzi_id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('删除成功')
                            this.Get_mechanically()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },


            //确定
            doClick() {
                let data = {
                    id: this.form.id,
                    name: this.form.name,
                    status: this.form.status,
                }
                //console.log(data)
                this.$sa0.post({
                    url: this.$api('Edit_mechanically'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },


            onMounted() {
                if (this.gongshi_id === 0) {
                    this.id = 0
                    this.name = '';
                    this.input = "";
                    this.status = 1;
                    this.yinzi_input = '';
                } else {
                    this.id = this.gongshi_id
                    this.getminfo()
                }
            },
            onClose() {
                this.closegongshi()
            },
            convert: function (symbol) {
                this.image = ''
                let fieldSplit = this.splitField(symbol)
                this.input = this.recombine(fieldSplit, symbol)
                document.getElementById('input').focus()
                let output = document.getElementById('output');
                output.innerHTML = '';
                MathJax.texReset();
                var options = MathJax.getMetricsFor(output);
                MathJax.tex2svgPromise(this.input, options).then(function (node) {
                    output.appendChild(node);
                    MathJax.startup.document.clear();
                    MathJax.startup.document.updateDocument();
                }).catch(function (err) {
                    console.log(err)
                }).then(function () {
                });
            },
            splitField() {
                let input_value_array = [];
                let select_field = document.getElementById('input')
                if (select_field.selectionStart === select_field.selectionEnd) {
                    input_value_array.push(select_field.value.substring(0, select_field.selectionStart));
                    input_value_array.push('');
                    input_value_array.push(select_field.value.substring(select_field.selectionStart, select_field.value.length));
                } else {
                    let word = '';
                    if (document.selection) {
                        let sel = document.selection.createRange();
                        if (sel.text.length > 0) {
                            word = sel.text;
                        }
                    } else if (select_field.selectionStart || select_field.selectionStart == '0') {
                        let startP = select_field.selectionStart;
                        let endP = select_field.selectionEnd;
                        if (startP != endP) {
                            word = select_field.value.substring(startP, endP);
                        }
                    }
                    input_value_array.push(select_field.value.substring(0, select_field.selectionStart));
                    input_value_array.push(word)
                    input_value_array.push(select_field.value.substring(select_field.selectionEnd, select_field.value.length));
                }
                return input_value_array
            },
            recombine: (fieldSplit, symbol) => {
                let result = fieldSplit[0]
                if (symbol === 'jia') {
                    result += '+' + fieldSplit[1]
                } else if (symbol === 'jian') {
                    result += '-' + fieldSplit[1]
                } else if (symbol === 'cheng') {
                    result += 'x' + fieldSplit[1]
                } else if (symbol === 'chu') {
                    result += '\\cfrac{' + fieldSplit[1] + '}'
                } else if (symbol === 'pingfang') {
                    result += fieldSplit[1] + '^{2}'
                } else if (symbol === 'genhao') {
                    result += '\\sqrt[2]{' + fieldSplit[1] + '}'
                } else if (symbol === 'denghao') {
                    result += fieldSplit[1] + '='
                } else {
                    result += fieldSplit[1]
                }
                result += fieldSplit[2]
                return result
            },
            getminfo() {
                this.$sa0.post({
                    url: this.$api('公式：公式详情'),
                    data: {
                        id: this.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.name = res.data.info.name
                            this.input = res.data.info.formula
                            this.status = res.data.info.status
                            this.image = res.data.info.image
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            savegongshi() {
                let this_ = this
                let svgXml = $("#output svg").prop("outerHTML")
                let image = new Image();
                image.src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svgXml))); //给图片对象写入base64编码的svg流
                image.onload = () => {
                    let canvas = document.createElement('canvas');  //准备空画布
                    canvas.width = $("#output svg").width() * 2;
                    canvas.height = $("#output svg").height() * 2;
                    let context = canvas.getContext('2d');  //取得画布的2d绘图上下文
                    context.drawImage(image, 5, 5);
                    let base64 = canvas.toDataURL('image/png')
                    let data = {
                        name: this.name,
                        formula: this.input,
                        status: this.status,
                        image: base64,
                    }
                    if (this.id === 0) {
                        this_.$sa0.post({
                            url: this_.$api('公式：创建公式'),
                            data: data,
                        }).then((response) => {
                            this.$sa0.response({
                                response: response.data,
                                then: (res) => {
                                    layer.msg('保存成功')
                                    this.id = res.data.mid
                                    this.getminfo()
                                },
                                error: (res) => {
                                    layer.msg(res.message)
                                }
                            })
                        })
                    } else {
                        data.id = this.id
                        this_.$sa0.post({
                            url: this_.$api('公式：修改公式'),
                            data: data,
                        }).then((response) => {
                            this.$sa0.response({
                                response: response.data,
                                then: (res) => {
                                    layer.msg('修改成功')
                                    this.getminfo()
                                },
                                error: (res) => {
                                    layer.msg(res.message)
                                }
                            })
                        })
                    }
                }
            },
        }
    }
</script>
<template>
    <div>
        <a-drawer width="100%" :title="gongshi_id===0?'新建公式':'编辑公式'" placement="right" :visible="true" @close="onClose">
            <div>
                <div>
                    <a-space align="center">
                        <div style="width: 100px;"><b>公式名称</b></div>
                        <div>
                            <a-input style="width: 800px;" v-model="name"></a-input>
                        </div>
                    </a-space>
                </div>
                <div class="mt-1">
                    <a-space align="start">
                        <div class="mt-1" style="width: 100px;"><b>公式</b></div>
                        <div>
                            <a-textarea style="width: 800px;letter-spacing:2px;" id="input" v-model="input" @change="convert('')"/>
                            <div class="mt-1">
                                <a-space>
                                    <a-button size="small" @click="convert('jia')">加</a-button>
                                    <a-button size="small" @click="convert('jian')">减</a-button>
                                    <a-button size="small" @click="convert('cheng')">乘</a-button>
                                    <a-button size="small" @click="convert('chu')">除</a-button>
                                    <a-button size="small" @click="convert('pingfang')">次方</a-button>
                                    <a-button size="small" @click="convert('genhao')">根号</a-button>
                                    <a-button size="small" @click="convert('denghao')">等号</a-button>
                                </a-space>
                            </div>
                        </div>
                    </a-space>
                </div>
                <div class="mt-1">
                    <a-space align="center">
                        <div style="width: 100px;"><b>公式图例</b></div>
                        <div>
                            <div v-if="image" id="output1">
                                <img :src="image" alt="">
                            </div>
                            <div v-show="!image" id="output"></div>
                        </div>
                    </a-space>
                </div>
                <div class="mt-1">
                    <a-space align="center">
                        <div style="width: 100px;"><b>状态</b></div>
                        <div>
                            <a-radio-group name="radioGroup" v-model="status">
                                <a-radio :value="1">
                                    可用
                                </a-radio>
                                <a-radio :value="2">
                                    不可用
                                </a-radio>
                            </a-radio-group>
                        </div>
                    </a-space>
                </div>
                <div class="mt-5">
                    <a-space align="center">
                        <div style="width: 100px;"><b></b></div>
                        <div>
                            <a-button type="primary" @click="savegongshi">保存</a-button>
                        </div>
                    </a-space>
                </div>
                <div v-if="gongshi_id !== 0" class="mt-10">
                    <a-space>
                        <div style="width: 100px;"><b>公式因子</b></div>

                        <div class="yinzilist">
                            <div v-for="( item,key) in mechanicallyData" :key="key" class="yinzilistwrap">
                                <a-space>
                                    <a-checkbox v-show="false" v-model="item.is_result"></a-checkbox>
                                    <span class="mr10">因子</span>
                                    <a-input v-model="item.yinzi" class="w100"></a-input>
                                    <a-button type="primary" class="ml10" @click="Edityinzi(item)">保存</a-button>
                                    <a-button type="danger" class="ml10" @click="Delyinzi(item)">删除</a-button>
                                </a-space>
                            </div>
                            <div class="yinzilist" style="margin-left: 10px">
                                <a-space>
                                    <span class="mr10">因子</span>
                                    <a-input v-model="creatform.yinzi_name" class="w100"></a-input>
                                    <a-button type="primary" class="ml10" @click="Addyinzi()">添加</a-button>
                                </a-space>
                            </div>
                        </div>


                    </a-space>
                </div>
            </div>
        </a-drawer>
    </div>
</template>
<style scoped>
    .fl{ float: left}

    .yinzilistwrap{ margin-bottom: 20px}

</style>
